import { useNavigate, useParams } from "react-router-dom";
import { ApisService } from "../app/core/apis.service";
import { dataDecrypt, dataEncrypt } from "../app/utils/encryption-helper";
import { useEffect, useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { AppConfig } from "../app/config";

export const RetailInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>();

  useEffect(() => {
    getInvoiceDataById();
  }, []);
  const getInvoiceDataById = async () => {
    setLoading(true);
    try {
      const reqData = {
        id: params.id,
      };
      const res = await ApisService.getRetailData(
        "findInvoiceById",
        dataEncrypt(JSON.stringify(reqData))
      );
      setLoading(false);
      const decryptData = JSON.parse(dataDecrypt(res.data.data));
      setInvoiceData(decryptData[0]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div className="container-fluid mt-2" style={{ fontFamily: "Sans-serif" }}>
      <span className="cursor-pointer primary-color" onClick={() => navigate(-1)}>{'<<'} Back</span>
      {loading ? (
        <div className="text-center">
          {Array.from({ length: 3 }).map((_, index) => (
            <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true" key={index} />
          ))}
        </div>) : (
        <div>
          <h4>
            Invoice: #<b>0{invoiceData?.invoice_number}</b>
          </h4>
          <Card className="shadow-lg border-0">
            <CardBody>
              <div className="d-flex justify-content-between align-items">
                <div>
                  <h5>Invoice Amount</h5>
                  <h5 className="fw-bold primary-color">
                    ₹ {invoiceData?.invoice_details?.invoice_amount}
                  </h5>
                </div>
                <div className="">
                  <a
                    target="_blank"
                    href={`${AppConfig.BASE_URL}/api/v1/http-utils/retailer-invoice/${invoiceData?._id}`}
                  >
                    <button className="rounded-pill px-4 fs-15 py-1" style={{ padding: '13px' }}>
                      Print
                    </button>
                  </a>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="my-2 shadow-lg border-0">
            <CardBody>
              <h5 className="fw-bold">Invoice Info</h5>
              {invoiceData?.invoice_details?.products &&
                invoiceData?.invoice_details?.products.map((value: any, index: any) => (
                  <Card className="mb-2" key={index}>
                    <CardBody>
                      <div className="primary-color">{value?.product_name}</div>
                      <div className="fs-15 my-1">Batch No : {value?.batch_number}</div>
                      <div className="fs-13 d-flex justify-content-between my-1">
                        <div>
                          <div className="fw-bold">Unit Price</div>
                          <div>₹ {value?.unit_price}</div>
                        </div>
                        <div>
                          <div className="fw-bold">Quantity</div>
                          <div>{value?.quantity}</div>
                        </div>
                        <div>
                          <div className="fw-bold">Total Amount</div>
                          <div>₹ {value?.product_amount_total}</div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}

              <table className="mt-2 table table-bordered fs-14">
                <tbody>
                  <tr>
                    <td>Total Price (₹)</td>
                    <td>{invoiceData?.invoice_details?.total_price}</td>
                  </tr>
                  <tr>
                    <td>Additional Discount</td>
                    <td>{invoiceData?.invoice_details?.additional_discount}</td>
                  </tr>
                  {invoiceData?.invoice_details?.additional_charges && invoiceData?.invoice_details?.additional_charges.map((value: any, index: any) => (<tr>
                    <td>{value?.label}</td>
                    <td>{value?.value}</td>
                  </tr>))}
                  <tr>
                    <td>Invoice Amount (₹)</td>
                    <td>{invoiceData?.invoice_details?.invoice_amount?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Paid (₹)</td>
                    <td>{invoiceData?.invoice_details?.amount_paid?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Due (₹)</td>
                    <td>{invoiceData?.invoice_details?.pending_amount?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>

            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};
