import React, {
  useState,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import { Card } from "react-bootstrap";
import OtpImage from "../assets/images/otp.jpg";
import LOGO_PNG from "../assets/images/vyapar_logo.png";

import { UserDataSlice } from "../app/store/userdata.slice";
import { useDispatch } from "react-redux";
import { ApisService } from "../app/core/apis.service";
import { dataDecrypt } from "../app/utils/encryption-helper";
interface Props {
  reference: string;
  transactionId?: string;
  type?: string;
}

const LoginOtp = (props: Props) => {
  const [otpInput, setOtpInput] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [otp , setOtp] = useState(); 
  let isOTPRequested = false;

  useEffect(() => {
    if (!isOTPRequested && props.reference) {
      requestOTP();
    }
  }, [props.reference]);

  const handleInputChange = (value: string) => {
    if (isNaN(Number(value))) {
      return;
    }
    if (/^\d{0,4}$/.test(value)) {
      setOtpInput(value);
      setError(false);
    }
    if (value.length === 4) {
      onVerifyOTP(value);
    }
  };

  const onVerifyOTP = async (value: any) => {
    setLoading(true);
    try {
      const verifyRes = await ApisService.verifyOTP(
        Number(value),
        props?.reference as string
      );
      setLoading(false);
      if (!verifyRes.data) {
        setError(true);
      }
      if (verifyRes.data) {
        dispatch(UserDataSlice.actions.otp(otpInput));
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const skipOTPVerification = async () => {
  //   dispatch(UserDataSlice.actions.otp(11));
  // };

  const requestOTP = async () => {
    setOtpInput('');
    setError(false);
    setLoading(true);
    try {
      switch (props.type) {
        case 'retail':
          const retail_data = await ApisService.getRetailInvoice(
            "sendRetailOTP",
            props.reference
          );
          const retaildecryptData = JSON.parse(dataDecrypt(retail_data?.data?.data));
          if(retaildecryptData){
           setOtp(retaildecryptData.contact); 
          }      
          break;
      
        default:
          const transaction_data = await ApisService.getTransactionData(
            "sendOfflineCustomerOTP",
            props.reference
          ); 
          const transactionData = JSON.parse(dataDecrypt(transaction_data?.data?.data));
          if(transactionData){
           setOtp(transactionData.contact); 
          }  
          break;
      }
      setLoading(false);
      isOTPRequested = true;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="d-flex justify-content-center ">
        <Card
          style={{ borderRadius: "10px", borderColor: "smokeWhite" }}
          className="otp-card p-3 mx-5 shadow-lg p-3 my-2 bg-body rounded"
        >
          <div className="text-center">
            <img
              src={LOGO_PNG}
              style={{ maxWidth: "100%", height: 200 }}
              alt="OTP Icon"
            />
          </div>
          <p className="text-center" style={{ color: "#ff9800" }}>
            Your one stop solution for business operations
          </p>
          {loading ? (
            <div className="text-center">
              {Array.from({ length: 3 }).map((_, index) => (
                <span className="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true" key={index} />
              ))}
            </div>
          ) : (
            <div className="text-center">
              <h6 className="otp-header mb-3">Enter OTP Code</h6>
              <div>Enter the OTP sent to {otp || `xxxxxxxxxx`}</div>
              <div className="otp-input-field mb-3 text-center">
                <input
                  className="form-control"
                  type="text"
                  placeholder="xxxx"
                  value={otpInput}
                  name=""
                  id=""
                  maxLength={4}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e.target.value)
                  }
                />
                {error && (
                  <div className="text-danger">
                    Invalid OTP. Please enter a valid OTP.
                  </div>
                )}
              </div>
              <p className="fs-14">
                Don't Receive the OTP?{" "}
                <span
                  className="text-primary cursor-pointer fw-bold"
                  onClick={requestOTP}
                >
                  RESEND OTP
                </span>
              </p>
              {/* <button className="text-center btn btn-primary otp border-0 mb-3" onClick={onVerifyOTP}>Verify OTP </button> */}
              {/* <p className="text-center text-primary otp border-0 cursor-pointer">
                <span onClick={skipOTPVerification}>Skip OTP for now</span>
              </p> */}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default LoginOtp;
