import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { dataDecrypt, dataEncrypt } from "../app/utils/encryption-helper";
import { useSelector } from "react-redux";
import LoginOtp from "./LoginOtp";
import { ApisService } from "./../app/core/apis.service";
import { AppConfig } from "../app/config";
import { FormatIndianDate } from "../app/utils/formatIndianDate";
import vyaparLogo from "../../src/assets/images/vyapar-logo.png"
import { CardBody } from "react-bootstrap";

export const Retail = () => {
  const routerParams = useParams();
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [pendingInvoiceData, setPendingInvoiceData] = useState<any>([]);
  const [completedInvoiceData, setCompletedInvoiceData] = useState<any>([]);
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const userData = useSelector((state: any) => state);
  const [loading, setLoading] = useState(false);
  const [otpReference, setOtpReference] = useState("");

  // /retail/info/:id
  function formatTimestamp(timestamp: number): string {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  }

  useEffect(() => {
    loadInvoice();
  }, []);

  useEffect(() => {
    if (userData?.UserDataReducer?.otp) {
      setIsOtpEntered(true);
    }
  }, [userData]);

  async function loadInvoice(): Promise<void> {
    setLoading(true);
    try {
      const reqPayload = routerParams.id as string;
      const decryptdata: any = JSON.parse(dataDecrypt(reqPayload));
      let payload = {
        buyerId: decryptdata.buyerId,
        sellerId: decryptdata.sellerId,
        filter: {
          DateFrom: null,
          DateTo: null,
        },
      };
      const EncryptData = dataEncrypt(JSON.stringify(payload));
      const preparedReference = dataEncrypt(
        JSON.stringify({
          bu: decryptdata.sellerId,
          customer: decryptdata.buyerId,
        })
      );
      setOtpReference(preparedReference);
      const res = await ApisService.getRetailInvoice(
        "getInvoices",
        EncryptData
      );
      const data: any = res.data.data;
      setLoading(false);
      const transactionData = JSON.parse(dataDecrypt(data));
      const pendingInvoices = transactionData?.results?.filter(
        (ele: any) => ele.payment_status === "pending"
      );
      const completedInvoices = transactionData?.results?.filter(
        (ele: any) => ele.payment_status === "completed"
      );

      setPendingInvoiceData(pendingInvoices);
      setCompletedInvoiceData(completedInvoices);
      setInvoiceData(transactionData);
    } catch (error) {
      console.error("Error in transaction:", error);
      setLoading(false);
    }
  }
  return (
    <div style={{fontFamily:"Sans-serif"}}>
      {!isOtpEntered && <LoginOtp type="retail" reference={otpReference} />}
      {isOtpEntered && (
        <>
          {invoiceData?.results &&<h2 className="ms-2 fw-bold primary-color text-center mt-2">{invoiceData?.results[0]?.buyer.name} Invoice List</h2>}
          <div className="container-fluid">
            {invoiceData?.results && <div
              style={{borderRadius: "3px" }}
            >
                  <div className="row align-items">
                    <div className="col-8 fs-14">
                    <div>{invoiceData?.results[0]?.seller?.name}</div>
                    <div>
                      {invoiceData?.results[0]?.seller?.address?.plot_no},
                      {invoiceData?.results[0]?.seller?.address?.buildinghousenumber},
                      {invoiceData?.results[0]?.seller?.address?.buildingstreetname},
                    </div>
                    <div>{invoiceData?.results[0]?.seller?.address?.villagetowncity},
                      {invoiceData?.results[0]?.seller?.address?.state},
                      {invoiceData?.results[0]?.seller?.address?.postcode},{" "}
                    </div>
                    <div>{invoiceData?.results[0]?.seller?.mobile_number}.</div>
                    </div>
                    <div className="text-end col-4">
                      <img src={vyaparLogo} alt="" className="responsive-width"/>
                    </div>
                  </div>
            </div>}
            <div className="row fs-14 mt-2">
              <div className="col-4 mb-2 text-center">
                <div className="fw-bold">Total Amount</div>
                <div>₹ {invoiceData.InvoiceTotalAmount}</div>
              </div>
              <div className="col-4 mb-2 text-center">
                <div className="fw-bold">Total Paid</div>
                <div>₹ {invoiceData.invoicePaidAmount}</div>
              </div>
              <div className="col-4 mb-2 text-center">
                <div className="fw-bold">Total Due</div>
                <div>₹ {invoiceData.invoicePendingAmount}</div>
              </div>
            </div>
            <div className="invoice-bg-clr rounded bg-white invoice-list px-2 pb-2">
              {pendingInvoiceData.length > 0 && <h5 className="text-secondary fw-bold ">Over Due</h5>}
              {pendingInvoiceData &&
                pendingInvoiceData.map((value: any, index: any) => (
                  <Card key={index} className="mb-2 shadow-sm border-0">
                    <Card.Body style={{ lineHeight: "0.6" }}>
                        <div className="d-flex justify-content-between align-items mb-2">
                        <div className="fs-6 primary-color">
                          <div className="fs-14">Due : <b>₹ {value?.pending_amount}</b></div>
                          <div className="mt-2 fs-14 text-secondary">Paid : ₹ {value?.paid_amount}</div>
                          </div>
                        <div>
                            <button className="rounded-pill px-4  fs-15 skyblue-bg-clr" style={{padding:'13px'}} onClick={()=>navigate(`/retail/info/${value?._id}`)}>
                              Invoice: # {value?.invoice_number?.toString().padStart(3, "0") || "001"}
                            </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items">
                      <div className="dark-orange">{FormatIndianDate(value.created_date,"datetime")}</div>
                      <h5 className="mb-0 text-center fw-bold me-4 primary-color">₹ {value.invoice_details.invoice_amount}</h5>
                      </div>
                    </Card.Body>
                  </Card>
                ))}

              {completedInvoiceData.length > 0 &&<h5 className="mb-1 mt-3 text-secondary fw-bold">Completed</h5>}
              {completedInvoiceData &&
                completedInvoiceData.map((value: any, index: any) => ( 
                  <Card key={index} className="mb-2 shadow-sm border-0">
                    <Card.Body style={{ lineHeight: "0.6" }}>
                      <div className="d-flex justify-content-between align-items">
                      <div className="mt-2 fs-14">Paid : ₹  {value?.paid_amount}</div>
                        <div>
                            <button className="rounded-pill px-4 mb-2 fs-15 dark-blue-bg" style={{padding:'13px'}} onClick={()=>navigate(`/retail/info/${value?._id}`)}>
                              Invoice: # {value?.invoice_number?.toString().padStart(3, "0") || "001"}
                            </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items">
                      <div className="skyblue-clr">{FormatIndianDate(value.created_date,"datetime")}</div>
                      <h5 className="mb-0 text-center fw-bold me-4 primary-color">₹ {value.invoice_details.invoice_amount}</h5>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
            </div>

          </div>
        </>
      )}
    </div>
  );
};
