import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHome } from "react-icons/ai";
import { PiArrowLeftBold } from "react-icons/pi";
import { Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import { dataDecrypt, dataEncrypt } from '../app/utils/encryption-helper';
import LoginOtp from './LoginOtp';
import { useSelector } from 'react-redux';
import LOGO from './../assets/images/vyapar_logo.png';
import { ApisService } from '../app/core/apis.service';
import { AppConfig } from '../app/config';
export const Delivery = () => {
  const [DeliveryData, setDeliveryData] = useState<any>([]);
  const [backRouteLink, setBackRouteLink] = useState('');
  const [otpReference, setOtpReference] = useState('');
  const userData = useSelector((state: any) => state)
  const routerParams = useParams();
  //time 
  function formatTimestamp(timestamp: number): string {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  }
  const created_date: string = formatTimestamp(DeliveryData[0]?.invoice_details?.invoice_date);
  console.log(created_date);

  useEffect(() => {
    loadDelivery();
  }, []);

  async function loadDelivery(): Promise<void> {

    try {
      
      const res = await ApisService.getTransactionData('finInvoiceById', routerParams.id || '');
      console.log('data', res.data);

      const data: any = res.data.data;
      const transactionData = JSON.parse(dataDecrypt(data));
      setDeliveryData(transactionData);
      if(transactionData[0]?.transactionId) {
        setBackRouteLink(dataEncrypt(JSON.stringify( {id: transactionData[0].transactionId})));
        const preparedReference = dataEncrypt(JSON.stringify({ invoice: transactionData[0].id, id: transactionData[0].transactionId, type: 'delivery' })); 
        setOtpReference(preparedReference);
      }
    } catch (error) {
      console.error('Error in transaction:', error);
    }
  }
  return (
    <div>
      {(!userData || !userData?.UserDataReducer || !userData?.UserDataReducer?.otp) && <LoginOtp reference={otpReference} />}
      {userData?.UserDataReducer?.otp && <>
        <div className='d-flex justify-content-between p-3' style={{ backgroundColor: "#274472", color: "white" }}>
        <Link to={`/order/${backRouteLink}`}> <span ><PiArrowLeftBold size={22} className='text-white' /></span></Link>
          <span style={{ lineHeight: "0", textAlign: "center" }}><h4>Delivery Details</h4><div>{DeliveryData[0]?.buyer?.name}</div></span>
          <img src={LOGO} alt="" height={60} />
        </div>
        <br />

        <div className='container-fluid'>
          <div style={{ backgroundColor: "white", borderRadius: "3px" }}>
            <Card className='mb-3'>
              <Card.Body>
                <div className='row'>
                  <h6 className='col-4'>Name</h6>
                  <span className='col-8'>: {DeliveryData[0]?.seller?.name}(Seller)</span>
                </div>

                <div className='row'>
                  <h6 className='col-4'>Txn Initiated Date</h6>
                  <div className='col-8'>: {created_date}</div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <h5 style={{ backgroundColor: "#274472" }} className='text-light p-3' >Direct Delivery</h5>

          <div className='card'>
            <div className='card.body'>
              <div className='row px-2 pt-3 pb-1'>
                <h6 className='col-6'>Business Unit</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.assigned_to_bu}</div>
              </div>
              <div className='row px-2 py-1'>
                <h6 className='col-6'>Contact Persion</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.assigned_to_user}</div>
              </div>
              <div className='row px-2 py-1'>
                <h6 className='col-6'>Contact Number</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.assigned_user_mobile_number}</div>
              </div>
              <div className='row px-2 py-1'>
                <h6 className='col-6'>Expected Delivery Date</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.delivery_date}</div>
              </div>
              <div className='row px-2 py-1'>
                <h6 className='col-6'>Expected Delivery Time</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.delivery_time}</div>
              </div>
              <div className='row px-2 py-1'>
                <h6 className='col-6'>Status</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.delivery_status}</div>
              </div>
              <div className='row px-2 py-1'>
                <h6 className='col-6'>GRN No</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.GRN_No}</div>
              </div>
            </div>
          </div>
          <h5 style={{ color: "#274472" }} className=' my-2' >Tracking Details</h5>
          <div className='card mb-2'>
            <div className='card.body'>
              <div className='row px-2 pt-2'>
                <h6 className='col-6' style={{ color: "#274472" }}> Document Type</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.delivery_details_arr[0]?.delivery_type}</div>
              </div>
              <div className='row px-2 pt-1'>
                <h6 className='col-6' style={{ color: "#274472" }}> Document Number</h6>
                <div className='col-6'>: {DeliveryData[0]?.invoice_details?.order_track?.delivery_details_arr[0]?.value}</div>
              </div>
            </div>
          </div>
          {DeliveryData[0]?.invoice_details?.order_track?.delivery_image && <div className="row mt-4 align-items-center">
            <h5 className='col-12' style={{ color: "#274472" }}>Delivery Image</h5>
            <div className="col-md-6">
              <img
                src={`${AppConfig?.IMAGE_PATH}${DeliveryData[0]?.invoice_details?.order_track?.delivery_image}`}
                className='w-100 rounded'
                alt="Packing"
                style={{ cursor: 'pointer', height: '200px' }}
                onClick={() => window.open(`${AppConfig?.IMAGE_PATH}${DeliveryData[0]?.invoice_details?.order_track?.delivery_image}`, '_blank')}
              />
            </div>
          </div>}
          <div className='text-center'>
              <Link to={`/order/${backRouteLink}`}><button className='px-4 py-2 rounded my-2 fs-14'>Go to Order Details</button></Link>
            </div>
        </div>
      </>}
    </div>
  )
}