import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import APPSTORE_ICON from './../assets/images/app-appstore.png';
import PLAYSTORE_ICON from './../assets/images/app-playstore.png';
interface Props {
    show: boolean;
    close: (val: boolean) => void;
}
export const DownloadInfo = (props: Props) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(props.show);
    }, [props.show])
    const handleClose = () => props.close(false);

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Vyapar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            To get benefits of these features to enhance your business efficiency subscribe to Vyapar Live application today
        </Modal.Body>
        <Modal.Footer>
            <div className="row">
                <div className="col-md-6 text-center my-4 text-lg-end">
                    <a href="https://play.google.com/store/apps/details?id=com.mrsoft.vyaparlive" target='_blank'>
                        <img src={PLAYSTORE_ICON} alt="" style={{maxWidth: '100%'}}/>
                    </a>
                </div>
                <div className="col-md-6 text-center my-4 text-lg-start">
                    {/* https://apps.apple.com/app/my-awesome-app/id123456789 */}
                    <a href="https://apps.apple.com/app/com.mrsoft.vyaparlive" target='_blank'>
                        <img src={APPSTORE_ICON} alt="" style={{maxWidth: '100%'}}/>
                    </a>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
}